import {makeAutoObservable, runInAction} from "mobx";


export class Index{

    constructor() {
        makeAutoObservable(this);
    }
    signedout: boolean = false;
    userId :string|null = null;
    registrationId: string|null = null;
    reason :string|null = null ;
    authStatus :string | null="configuring";
    setSignedout(val:boolean){
        runInAction(() => {
        this.signedout = val;
        });
    }
    setUserId(userId:string){
        runInAction(() => {
        this.userId = userId;
        });
    }
    setReason(reason:string){
        runInAction(() => {
            this.reason = reason;
        });
    }
    setAuthStatus(authStatus:string | null){
            this.authStatus = authStatus;
    }

    setRegistrationId(regId:string){
        runInAction(() => {
        this.registrationId = regId;
        });
    }
    isPsnaProvider:boolean = false;
    setIsPsnaProvider(val:boolean) {
        runInAction(() => {
            this.isPsnaProvider = val;
        });
    }

}