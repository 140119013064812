import {makeAutoObservable, runInAction} from "mobx";

export class AdminPolicyHolderClaimsTable {
    constructor() {
        makeAutoObservable((this));
    }
    public openRejectDialog:boolean = false;
    setOpenRejectDialog(val:boolean) {
        runInAction(() => {
        this.openRejectDialog = val;});
    }
    public rejectionReason:string = "";
    setRejectionReason(val:string) {
        runInAction(() => {
            this.rejectionReason = val;});
    }
    public idToDelete:number|null = null;
    setIdToDelete(val:number|null) {
        runInAction(() => {
            this.idToDelete = val;});
    }
}