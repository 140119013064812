import {makeAutoObservable, runInAction} from "mobx";

export class RejectionReason {
    constructor() {
        makeAutoObservable(this)

    }
    public itemId: string = ""
    setItemId(val:string) {
        runInAction(() => {
            this.itemId = val;});
    }
    public openRejectionDialog :boolean = false
    setOpenRejectionDialog(val:boolean) {
        runInAction(() => {
            this.openRejectionDialog = val;});
    }
    public rejectionReason:string="";
    setRejectionReason(val:string) {
        runInAction(() => {
            this.rejectionReason = val;});
    }
}
