
import {fetchAuthSession, fetchUserAttributes} from 'aws-amplify/auth';
import './App.css';
import {Amplify} from "aws-amplify";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { Stack, Typography} from "@mui/material";
import logo from './images/logo512.png'
import { observer } from "mobx-react-lite"
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {RestLink} from "apollo-link-rest";
import {PermissionProvider} from "./providers/PermissionProvider";
import {useStore} from "./providers/StoreContext";

Amplify.configure({
  Auth: {
    Cognito: {

      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
      userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID ?? "",
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? "",
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.REACT_APP_DOMAIN ?? "",
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: [process.env.REACT_APP_REDIRECT ?? ""],
          redirectSignOut: [process.env.REACT_APP_REDIRECT ?? ""],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  },
  Storage:{
    S3:{
      bucket: process.env.REACT_APP_BUCKET_NAME ?? "",
      region: process.env.REACT_APP_REGION ?? "",
    }
  }
})

const formFields = {
  forceNewPassword:{
    gender:{
      placeholder: 'Enter User Gender',
      label: 'Gender',
      order: 10
    }
  },
  signUp: {
    email: {
      placeholder: 'Enter your Email',
      order:1
    },
    family_name: {
      order: 3
    },
    given_name: {
      order: 4
    },
    phone_number:{
      order: 5
    },
    password: {
      order: 6
    },
    confirm_password: {
      order: 7
    }
  },
  confirmVerifyUser: {
    confirmation_code: {
      label: 'confirmation Code',
      placeholder: 'If you received confirmation link just click on it no need to fill this part',
      isRequired: false,
    },
  }
}


 const App = observer(()=> {
   const store = useStore();
   let hasToken = store.appStore.token !=="";
   const  httpLink = createHttpLink({
     uri:process.env.REACT_APP_GRAPHQL_ENDPOINT
   });

   const restLink =new RestLink({
     endpoints:
         {batch:`${process.env.REACT_APP_BATCH_ENDPOINT}`},
     uri:`${process.env.REACT_APP_API_ENDPOINT}`
   });


   const {user} = useAuthenticator((context)=>[context.user]);
   useEffect(() => {
     store.indexStore.setUserId(user?.userId);
   }, [user,store.indexStore]);

   if (user && !hasToken) {
     fetchAuthSession().then(data => {
       if (data.tokens){
           store.appStore.setToken(data?.tokens?.accessToken.toString());
           store.appStore.setIdentityId(data?.identityId);
           fetchUserAttributes().then(attrib => {
               store.psnaToolBarStore.setUserFirstName(attrib?.given_name ?? "");
               store.psnaToolBarStore.setUserLastName(attrib?.family_name ?? "");
             store.psnaToolBarStore.setUserEmail(attrib?.email ?? "");
           })
       }
     });
   }
   const authLink = setContext((_, { headers }) => {
     return {
       headers: {
         ...headers,
         authorization: store.appStore.token ? `Bearer ${store.appStore.token}` : "token not found",
       }
     }
   });
   const client = new ApolloClient({
     link:ApolloLink.from([authLink,restLink,httpLink]),
     cache: new InMemoryCache()
   });
   let renderLogo = store.indexStore.userId ==null;

   return (
         <Stack alignItems="center" spacing={{xs: 1, sm: 2}}>
           {renderLogo &&
               <>
                 <img width='150px' height='150px' src={logo} alt='Logo'></img>
                 <Typography component="div" variant="h5">Service Provider Onboarding Portal</Typography>
               </>
           }
           <Authenticator loginMechanisms={['email']}  formFields={formFields}>{() => (
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                 { hasToken && <ApolloProvider client={client}>

                   <PermissionProvider>
                       <Outlet></Outlet>
                   </PermissionProvider>

                 </ApolloProvider>}
               </LocalizationProvider>
           )}
           </Authenticator>
         </Stack>
   );
 })


export default App;
