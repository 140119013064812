import {Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "./providers/StoreContext";
import {useQuery} from "@apollo/client";
import {GET_CATALOGS} from "./Queries";


export const PolicyholderClaimFilter = observer(({controlType}:any) => {
    const store = useStore();
    const {loading, data} = useQuery(GET_CATALOGS
        );
    // @ts-ignore
    return (
        <Stack direction="row" sx={{minWidth:"80%", justifyContent:'center', mt:12 }} spacing={1} >
            <FormControl sx={{xs:4, sm:8,md:4,lg:4,xl:4}} style={controlType =="Admin" ? {display:"block"}:{display:"none"}}>
                <TextField   sx={{background:"white"}} label='Provider Reg Id'
                             onChange={(data)=>store.PolicyholderClaimFilterStore.setProviderRegNumber(data.target.value)}
                             onBlur={(data)=>store.PolicyholderClaimFilterStore.setProviderRegNumber(data.target.value)}
                             value={store.PolicyholderClaimFilterStore.ProviderRegNumber}
                />
            </FormControl>

            <FormControl sx={{xs:4, sm:8,md:4,lg:4,xl:4}}>
                <TextField   sx={{background:"white"}} label='Claim Id'
                             onChange={(data)=>store.PolicyholderClaimFilterStore.setClaimId(data.target.value)}
                             onBlur={(data)=>store.PolicyholderClaimFilterStore.setClaimId(data.target.value)}
                             value={store.PolicyholderClaimFilterStore.ClaimId}
                />
            </FormControl>

            <FormControl sx={{xs:4, sm:8,md:4,lg:4,xl:4}}>
                        <TextField   sx={{background:"white"}} label='Employee Number'
                        onChange={(data)=>store.PolicyholderClaimFilterStore.setEmployeeNo(data.target.value)}
                        onBlur={(data)=>store.PolicyholderClaimFilterStore.setEmployeeNo(data.target.value)}
                        value={store.PolicyholderClaimFilterStore.EmployeeNo}
                        />
            </FormControl>
            <FormControl sx={{minWidth:"40%"}}   >
                                <Autocomplete sx={{background:"white"}} id="ClaimCode"
                                              loading={loading}
                                              options={loading ? [] : data?.catalogs}
                                              onChange={(input, value: any)=>store.PolicyholderClaimFilterStore.setClaimCode(value?.id ?? '')}
                                    renderInput={(params) => <TextField {...params} label="Select Claim Type" />}/>

            </FormControl>
            <FormControl sx={{minWidth:"10%"}} >
            <InputLabel id="Status_label">Status</InputLabel>


            <Select sx={{background:"white"}} id="Status" label="Status"
                    labelId="Status_label"
                    onChange={(data)=>store.PolicyholderClaimFilterStore.setStatus(data.target.value)}
                    value={store.PolicyholderClaimFilterStore.Status}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
        </FormControl>
        </Stack>
    );
});