import {makeAutoObservable, observable, runInAction} from "mobx";

export class Portal {
    constructor() {
        makeAutoObservable(this)

    }
    // set for downloadUrl according to mbox guidelines
    downloadCertificateUrl :string = "";
    setDownloadCertificateUrl (fileName:string  ){
        runInAction(() => {
        this.downloadCertificateUrl = fileName;
    })}

    registrationId :string = "";
    setRegistrationId (id:string  ){
        runInAction(() => {
        this.registrationId = id;
    })}
}
