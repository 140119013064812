import {makeAutoObservable, runInAction} from "mobx";

export class PolicyholderClaimFilter {
    constructor() {
        makeAutoObservable((this));
    }
    public EmployeeNo:string = "";
    setEmployeeNo(employee_number:string) {
        runInAction(() => {
        this.EmployeeNo = employee_number;});
    }
    public ProviderRegNumber:string = "";
    setProviderRegNumber(providerRegistration:string) {
        runInAction(() => {
        this.ProviderRegNumber = providerRegistration;});
    }
    public ClaimId:string = "";
    setClaimId(claimId:string) {
        runInAction(() => {
        this.ClaimId = claimId;});
    }
    public ClaimCode:string = '';
    setClaimCode(claimType:string) {
        runInAction(() => {
        this.ClaimCode = claimType;});
    }
    public Status:string = "";
    setStatus(status:string) {
        runInAction(() => {
            this.Status = status;
        });
    }
}