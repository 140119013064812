import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";

import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {

    GET_POLICYHOLDERCLAIMS,
 SIGNURL,
    UPDATE_CLAIMSTATUS,

} from "./Queries";
import {useStore} from "./providers/StoreContext";
import {observer} from "mobx-react-lite";

import React from "react";
import {ViewFilesComponent} from "./ViewFilesComponent";
import {ClaimFile} from "./store/ViewFiles";
import {InfoOutlined} from "@mui/icons-material";

export const AdminPolicyHolderClaimsTable =  observer(() => {
    const store = useStore();
    const client = useApolloClient();
    const {loading, error, data} = useQuery(GET_POLICYHOLDERCLAIMS,
        {variables:
                {
                    userId:"",
                    providerRegNumber:store.PolicyholderClaimFilterStore.ProviderRegNumber,
                    claimId: store.PolicyholderClaimFilterStore.ClaimId,
                    employeeNo:store.PolicyholderClaimFilterStore.EmployeeNo,
                    claimCode:store.PolicyholderClaimFilterStore.ClaimCode,
                    status:store.PolicyholderClaimFilterStore.Status,
                }});
    const [updateStatus] = useMutation(UPDATE_CLAIMSTATUS,{awaitRefetchQueries:true, refetchQueries: [GET_POLICYHOLDERCLAIMS, 'getPolicyHolderClaims']});
    const UpdateRejection=()=>{
        store.rejectionReasonStore.setOpenRejectionDialog(false)
        updateStatus({variables: {"id": store.rejectionReasonStore.itemId, "status": "Rejected", "reason":store.rejectionReasonStore.rejectionReason}}).then();
    }
    const UpdateApprove=()=>{
        store.approval.setOpenDialog(false)
        updateStatus({variables: {"id": store.approval.itemId, "status": "Approved", "reason":""}}).then();
    }
    const viewFilesAction = (id:number, files:string, userBucket:string) => {
        store.ViewFilesStore.setFileString(files);
        store.ViewFilesStore.setOpenfileDialog(true);
        store.ViewFilesStore.setClaimFiles([]);
        store.ViewFilesStore.Files.forEach((file) => {
            client.query({
                query:SIGNURL,
                variables: {
                    input: {
                        bucket: process.env.REACT_APP_BUCKET_NAME,
                        key: `private/${userBucket}/documents/${id}_${file}`
                    }
                }
            }).then((d)=>{
                let claimFile = new ClaimFile();
                claimFile.setName(file);
                claimFile.setUrl(d?.data.signurl.url.toString());
                store.ViewFilesStore.claimFiles.push(claimFile);
            });

        });

    }
    if (loading)
        return <div style={{alignSelf:'center'}}>Loading...</div>;
    if (error)
        return  <div style={{alignSelf:'center'}}>{error.message}</div>;

    if (data.policyHolderClaims.length > 0) {
        return <>
        <TableContainer component={Paper}>
            <Table key="DataTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Provider</TableCell>
                        <TableCell>Claim Id</TableCell>
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Claim</TableCell>
                        <TableCell>Amount (PGK)</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>View Files</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.policyHolderClaims.map((row: any, idx: number) => (
                        <TableRow key={idx}>
                            <TableCell>{row.providerRegNumber}</TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.employeeNo}</TableCell>
                            <TableCell>{row.label}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                                <Stack direction={"row"} spacing={1}>
                                    <span>
                                {row.status}
                                        </span>
                                    <Tooltip title={row.reason}>
                                <InfoOutlined style={row.status === "Rejected" ? {display:"inline"} : {display:"none"}} color="primary"></InfoOutlined>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Button disabled={row.documents ===""}
                                    variant="contained"
                                    onClick={()=>viewFilesAction(row.id, row.documents,row.userBucket)}
                                >
                                    <span>View Files</span>
                                </Button></TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1} >
                                <Button
                                    disabled={row.status ==="Rejected"}
                                variant="contained"
                                onClick={()=>{store.rejectionReasonStore.setItemId(row.id); store.rejectionReasonStore.setOpenRejectionDialog(true)}}
                                >
                                <span>Reject</span>
                                </Button>
                                    <Button
                                        disabled={row.status ==="Approved"}
                                        variant="contained"
                                        onClick={()=>{store.approval.setItemId(row.id); store.approval.setOpenDialog(true)}}
                                    ><span>Approve</span>

                            </Button>
                                </Stack>
                                </TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
            <Dialog open = {store.rejectionReasonStore.openRejectionDialog}  >
                <DialogTitle>Reason</DialogTitle>
                <DialogContent>
                    <textarea rows={10} cols={50}
                              onChange={event => store.rejectionReasonStore.setRejectionReason( event.target.value)}></textarea>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        store.rejectionReasonStore.setOpenRejectionDialog(false)}}>Cancel</Button>
                    <Button onClick={UpdateRejection}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {store.approval.openDialog}  >
                <DialogTitle>Claim Approval</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to approve this claim?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.approval.setOpenDialog(false)}>No</Button>
                    <Button onClick={UpdateApprove}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {store.ViewFilesStore.openfileDialog}  >
                <DialogTitle>List of the Files Attached to the Claim</DialogTitle>
                <DialogContent>
                    <ViewFilesComponent/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.ViewFilesStore.setOpenfileDialog(false)}>Close</Button>

                </DialogActions>
            </Dialog>

        </>
    }
    else {
            return <div style={{alignSelf:'center'}}>There is not any Claim meeting filter criteria</div>;
        }

});
