import {makeAutoObservable, runInAction} from "mobx";

export class PolicyHolderClaimsTable {
    constructor() {
        makeAutoObservable((this));
    }
    public openVerificationDialog:boolean = false;
    setOpenVerificationDialog(val:boolean) {
        runInAction(() => {
        this.openVerificationDialog = val;});
    }

    public idToDelete:number|null = null;
    setIdToDelete(val:number|null) {
        runInAction(() => {
            this.idToDelete = val;});
    }
}