
import {makeAutoObservable, runInAction} from "mobx";

export class PsnaToolBar{
    constructor() {
        makeAutoObservable(this);
    }


    userFirstName :string | null = null;
    setUserFirstName (firstName:string | null ){
        runInAction(() => {
            this.userFirstName = firstName;
        });
    }

    userLastName :string | null = null;
    setUserLastName (lastName:string | null ){
        runInAction(() => {
            this.userLastName = lastName;
        });
    }
    userEmail :string | null = null;
    setUserEmail (email:string | null ){
        runInAction(() => {
            this.userEmail = email;
        });
    }

    showRegistrationForm:boolean = true;
    setShowRegistrationForm(val:boolean) {
        runInAction(() => {
            this.showRegistrationForm = val;
        });
    }
}