import {makeAutoObservable, runInAction} from "mobx";

export class AddPolicyholderClaim {
    constructor() {
        makeAutoObservable((this));
    }
    public openErrorDialog:boolean = false;
    setOpenErrorDialog(val:boolean) {
        runInAction(() => {
        if (!val)
            this.errorMessage="";
        this.openErrorDialog = val;});
    }

    public errorMessage:string = "";
    setErrorMessage(val:string) {
        runInAction(() => {
            this.errorMessage = val;});
    }
    public openVerificationDialog:boolean = false;
    setOpenVerificationDialog(val:boolean) {
        runInAction(() => {
            this.openVerificationDialog = val;});
    }

    public formData:any = null;
    setFormData(val:any) {
        runInAction(() => {
            this.formData = val;});
    }

    public files:any[] = [];
    setFiles(val:any[]|null) {
        runInAction(() => {
            this.files = val ?? [];
        });
    }
    public verificationData:any = null;
    setVerificationData(val:any) {
        runInAction(() => {
            this.verificationData = val;});
    }

    public resetDocuments:boolean = false;
    setResetDocuments(val:any) {
        runInAction(() => {
            this.resetDocuments = val;});
    }
    public uploading:boolean = false;
    setUploading(val:boolean) {
        runInAction(() => {
            this.uploading = val;});
    }

    public openBankDetailsDialog:boolean = false;
    setOpenBankDetailsDialog(val:boolean) {
        runInAction(() => {
            this.openBankDetailsDialog = val;});
    }

    public employeeNumber:string = "";
    setEmployeeNumber(val:string) {
        runInAction(() => {this.employeeNumber = val})
    }
}