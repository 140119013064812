import {Registration} from "./Registration";
import { makeAutoObservable} from "mobx";
import {Index} from "./Index";
import {PsnaToolBar} from "./PsnaToolBar";
import {App} from "./App";
import {RejectionReason} from "./RejectionReason";
import {AppPermissions} from "./AppPermissions";
import {RegistrationFilter} from "./RegistrationFilter";
import {Approval} from "./Approval";
import {Portal} from "./Portal";
import {UploadPolicyHolders} from "./UploadPolicyHolders";
import {Acknowledgement} from "./Acknowledgement";
import {PolicyholderClaimFilter} from "./PolicyholderClaimFilter";
import {AddPolicyholderClaim} from "./AddPolicyholderClaim";
import {PolicyHolderClaimsTable} from "./PolicyHolderClaimsTable";
import {ViewFiles} from "./ViewFiles";
import {AdminPolicyHolderClaimsTable} from "./AdminPolicyHolderClaimsTable";

export class Store{
    constructor() {
        makeAutoObservable(this)
    }
    rejectionReasonStore: RejectionReason = new RejectionReason();
    approval: Approval = new Approval();
    registrationFilterStore: RegistrationFilter =new RegistrationFilter();
    registrationStore:Registration = new Registration();
    indexStore: Index =new Index();
    psnaToolBarStore : PsnaToolBar=new PsnaToolBar();
    appStore : App = new App();
    permissionsStore:AppPermissions = new AppPermissions();
    portalStore:Portal = new Portal();
    UploadPolicyHoldersStore = new UploadPolicyHolders();
    AcknowledgementStore = new Acknowledgement();
    PolicyholderClaimFilterStore:PolicyholderClaimFilter = new PolicyholderClaimFilter();
    AddPolicyholderClaimStore = new AddPolicyholderClaim();
    PolicyHolderClaimsTableStore = new PolicyHolderClaimsTable();
    ViewFilesStore = new ViewFiles();
    AdminPolicyHolderClaimsTableStore = new AdminPolicyHolderClaimsTable();
    reset(){
        this.approval = new Approval();
        this.registrationStore = new Registration();
        this.indexStore = new Index();
        this.psnaToolBarStore = new PsnaToolBar();
        this.appStore = new App();
        this.rejectionReasonStore = new RejectionReason();
        this.permissionsStore = new AppPermissions();
        this.portalStore = new Portal();
        this.UploadPolicyHoldersStore = new UploadPolicyHolders();
        this.AcknowledgementStore= new Acknowledgement();
        this.PolicyholderClaimFilterStore = new PolicyholderClaimFilter();
        this.AddPolicyholderClaimStore = new AddPolicyholderClaim();
        this.PolicyHolderClaimsTableStore = new PolicyHolderClaimsTable();
        this.ViewFilesStore = new ViewFiles();
        this.AdminPolicyHolderClaimsTableStore = new AdminPolicyHolderClaimsTable();
    }
}