import {observer} from "mobx-react-lite";
import {useStore} from "./providers/StoreContext";

import {getUrl} from "aws-amplify/storage";

export const DownloadCertificate =  observer(()=> {
    const store = useStore();

    getUrl({
        path:({identityId})=>`private/${identityId}/${store.portalStore.registrationId}certificate.pdf`,

        options: {

            expiresIn: 900// validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        },
    }).then((data) => {
        store.portalStore.setDownloadCertificateUrl(data.url.toString());
    });
    return (

        // if store.portalStore.downloadUrl is not empty, then show loading message
        // else, show the download link
        store.portalStore.downloadCertificateUrl === "" ? <div>Loading ..</div> : <a href={store.portalStore.downloadCertificateUrl} target='_blank'>Download your certificate</a>
    );
});