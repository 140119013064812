import {observer} from "mobx-react-lite";
import {useStore} from "./providers/StoreContext";
import {ClaimFile} from "./store/ViewFiles";
import {Stack} from "@mui/material";

export const ViewFilesComponent =  observer(() => {
    const store = useStore();
    let parray:Promise<any>[] = [];
   // store.ViewFilesStore.setReadyToShow(false);
    let claimFiles:ClaimFile[] =[]

    return (
        <Stack>
            {store.ViewFilesStore.claimFiles.map((row: any) => (
                <a target="_top" href={row.Url}>{row.Name}</a>))}
        </Stack>
    );
});