import {makeAutoObservable, runInAction} from "mobx";

export class Approval {
    constructor() {
        makeAutoObservable(this)

    }
    public itemId: string = ""
    setItemId(val:string) {
        runInAction(() => {
            this.itemId = val;
        })
    };

    public openDialog :boolean = false
    setOpenDialog(val:boolean) {
        runInAction(() => {
            this.openDialog = val;
        })
    };
}
