import {observer} from "mobx-react-lite";

import {PolicyholderClaimFilter} from "./PolicyholderClaimFilter";
import {useStore} from "./providers/StoreContext";
import {AdminPolicyHolderClaimsTable} from "./AdminPolicyHolderClaimsTable";
import {Stack} from "@mui/material";

export const ApproveClaims =  observer(()=> {
    const store = useStore();
    store.psnaToolBarStore.setShowRegistrationForm(false);
    return (
        <Stack spacing={2}>
            <PolicyholderClaimFilter controlType={"Admin"}></PolicyholderClaimFilter>
            <AdminPolicyHolderClaimsTable></AdminPolicyHolderClaimsTable>
        </Stack>
    );
});