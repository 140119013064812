import {makeAutoObservable, runInAction} from "mobx";
export class ClaimFile{
    constructor() {
        makeAutoObservable(this)
    }
    Name: string = ""
    setName = (val:string)=>{
        runInAction(() => {
            this.Name = val;
        });
    }

    Url: string = ""
    setUrl = (val:string)=>{
        runInAction(() => {
            this.Url = val;
        });
    }


}
export class ViewFiles {
    constructor() {
        makeAutoObservable(this)

    }
    fileString: string = ""
    setFileString = (val:string)=>{
        runInAction(() => {
            this.fileString = val;
            let regex = /"([^"]+)"/g;
            this.files =(this.fileString.match(regex)?.map((val)=>val.replace(/"/g,""))||[]);
        });
    }

    files:string[] = []
    get Files(){
        return this.files
    }

    claimFiles:ClaimFile[] = []
    setClaimFiles = (val:ClaimFile[])=>{
        runInAction(() => {
            this.claimFiles = val;
        });
    }

    claimId : number = 0
    setClaimId = (val:number)=>{
        runInAction(() => {
            this.claimId = val;
        });
    }

    openfileDialog :boolean = false
    setOpenfileDialog = (val:boolean)=>{
        runInAction(() => {
            this.openfileDialog = val;
        });
    }
    readyToShow :boolean = false
    setReadyToShow = (val:boolean)=>{
        runInAction(() => {
            this.readyToShow = val;
        });
    }

}
