import { makeAutoObservable} from "mobx";
import {RegistrationData} from "./RegistrationData";


export class Registration {
    constructor() {
        makeAutoObservable(this);
    }
    registrationData :RegistrationData|null = new RegistrationData();
    callGetData:boolean = true;
    urlComputed:boolean = false;
    error:string|null = null;
    uploadingSignatureFile=false;
    uploadingIpa = false;
    uploadingTin = false;
    uploadingMedical = false;
    expandPanels = false;
    activePanel :string|boolean = "panel1";
    ipaDownloadUrl = null;
    tinDownloadUrl = null;
    tinName: string = "";
    ipaName: string ="";
    medicalName: string ="";
    medicalDownloadUrl = null;
    setTinName(value:string){
        this.tinName = value;
    }
    setIpaName(value:string){
        this.ipaName = value;
    }
    setMedicalName(value:string){
        this.medicalName = value;
    }
    setCallGetData(val: boolean){
        this.callGetData = val;
    }
    setUrlComputed(val: boolean){
        this.urlComputed = val;
    }
    setUploadingSignatureFile(val : boolean){
        this.uploadingSignatureFile = val;
    }
    toggleIpaUploading(){
        this.uploadingIpa = !this.uploadingIpa;
    }
    toggleTinUploading(){
        this.uploadingTin = !this.uploadingTin;
    }
    toggleMedicalUploading(){
        this.uploadingMedical = !this.uploadingMedical;
    }
    setExpandPanels(val: boolean){
        this.expandPanels = val;
    }
    setActivePanel(panelName:string|boolean){
        this.activePanel = panelName;
    }
    setIpaDownloadUrl(val: any){
        this.ipaDownloadUrl = val;
    }
    setTinDownloadUrl(val: any ){
        this.tinDownloadUrl =val;
    }
    setMedicalDownloadUrl(val: any){
        this.medicalDownloadUrl = val;
    }

    setRegistrationData(val: any){
        if (this.registrationData) {
            this.registrationData.id = val.id ;
            this.registrationData.userId = val.userId;
            this.registrationData.public_officer_firstname = val.public_officer_firstname;
            this.registrationData.public_officer_lastname = val.public_officer_lastname;
            this.registrationData.ipa_Certified_Number = val.ipa_Certified_Number;
            this.registrationData.mb_Registration_Number = val.mb_Registration_Number;
            this.registrationData.rn_Expiry = val.rn_Expiry;
            this.registrationData.applicantsTermsInPractice = val.applicantsTermsInPractice;
            this.registrationData.postal_Section = val.postal_Section;
            this.registrationData.postal_Lot = val.postal_Lot;
            this.registrationData.postal_Street = val.postal_Street;
            this.registrationData.postal_Suburb = val.postal_Suburb;
            this.registrationData.postal_Province = val.postal_Province;
            this.registrationData.business_Phone_Number = val.business_Phone_Number;
            this.registrationData.mobile_Phone_Number = val.mobile_Phone_Number;
            this.registrationData.email = val.email;
            this.registrationData.location_Creation_Date = val.location_Creation_Date;
            this.registrationData.practice_Name = val.practice_Name;
            this.registrationData.practice_Section = val.practice_Section;
            this.registrationData.practice_Lot = val.practice_Lot;
            this.registrationData.practice_Street = val.practice_Street;
            this.registrationData.practice_Suburb = val.practice_Suburb;
            this.registrationData.practice_Province = val.practice_Province;
            this.registrationData.location_Phone_Number = val.location_Phone_Number;
            this.registrationData.location_Email = val.location_Email;
            this.registrationData.applicant_Employment_Status = val.applicant_Employment_Status;
            this.registrationData.registered_Business_Name = val.registered_Business_Name;
            this.registrationData.ipa_Registration_Number = val.ipa_Registration_Number;
            this.registrationData.business_Type = val.business_Type;
            this.registrationData.premises = val.premises;
            this.registrationData.bank = val.bank;
            this.registrationData.branch_Number = val.branch_Number;
            this.registrationData.branch_Name = val.branch_Name
            this.registrationData.account_Number = val.account_Number;
            this.registrationData.account_Name = val.account_Name;
            this.registrationData.medical_Practitioner_firstname = val.medical_Practitioner_firstname;
            this.registrationData.medical_Practitioner_lastname = val.medical_Practitioner_lastname;
            this.registrationData.created_Date = val.created_Date;
            this.registrationData.updated_Date = val.updated_Date;
            this.registrationData.ipa_Certificate = val.ipa_Certificate;
            this.registrationData.tin_Certificate = val.tin_Certificate;
            this.registrationData.medical_Certificate = val.medical_Certificate;
            this.registrationData.luhnRegistrationNumber = val.luhnRegistrationNumber;
            this.registrationData.medical_Practitioner_Signiture = val.medical_Practitioner_Signiture;
            this.registrationData.pbox_Name =val.pbox_Name;
            this.registrationData.pbox_Number = val.pbox_Number;
            this.registrationData.pbox_Province = val.pbox_Province;
            this.registrationData.pbox_Branch = val.pbox_Branch;
            this.registrationData.isPsnaProvider = false;
        }
    }
    setError(val:string){
        this.error = val;
    }


    get isSaving(){
        return this.uploadingSignatureFile || this.uploadingIpa || this.uploadingTin || this.uploadingMedical;
    }
    get expandPanel1(){
        return this.expandPanels || this.activePanel === "panel1"
    }
    get expandPanel2(){
        return  this.expandPanels || this.activePanel === "panel2"
    }
    get expandPanel3(){
        return this.activePanel === "panel3" || this.expandPanels
    }
    get expandPanel4(){
        return this.activePanel === "panel4" || this.expandPanels
    }
 }


