import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";

import {useMutation, useQuery} from "@apollo/client";
import {DELETE_CLAIM, GET_POLICYHOLDERCLAIMS} from "./Queries";
import {useStore} from "./providers/StoreContext";
import {observer} from "mobx-react-lite";

import React from "react";
import {ViewFilesComponent} from "./ViewFilesComponent";
import {getUrl} from "aws-amplify/storage";
import {ClaimFile} from "./store/ViewFiles";
import {InfoOutlined} from "@mui/icons-material";

export const PolicyHolderClaimsTable =  observer(() => {
    const store = useStore();
    const {loading, error, data} = useQuery(GET_POLICYHOLDERCLAIMS,
        {variables:
                {
                    userId:store.indexStore.userId,
                    providerRegNumber:"",
                    claimId: store.PolicyholderClaimFilterStore.ClaimId,
                    employeeNo:store.PolicyholderClaimFilterStore.EmployeeNo,
                    claimCode:store.PolicyholderClaimFilterStore.ClaimCode,
                    status:store.PolicyholderClaimFilterStore.Status,
                }});
    const [deleteClaim] =useMutation(DELETE_CLAIM, {awaitRefetchQueries:true, refetchQueries: [GET_POLICYHOLDERCLAIMS, 'getPolicyHolderClaims']});
    const viewFilesAction = (id:number, files:string) => {
        store.ViewFilesStore.setFileString(files);
        store.ViewFilesStore.setOpenfileDialog(true);
        store.ViewFilesStore.setClaimFiles([]);
        store.ViewFilesStore.Files.forEach((file) => {
            getUrl({
                path:({identityId})=>`private/${identityId}/documents/${id}_${file}`,
            }).then((url)=>{
                let claimFile = new ClaimFile();
                        claimFile.setName(file);
                       claimFile.setUrl(url.url.toString());
                       store.ViewFilesStore.claimFiles.push(claimFile);
            });
        });

    }
    if (loading)
        return <div style={{alignSelf:'center'}}>Loading...</div>;
    if (error)
        return  <div style={{alignSelf:'center'}}>{error.message}</div>;

    if (data.policyHolderClaims.length > 0) {
        return <>
        <TableContainer component={Paper}>
            <Table key="DataTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Claim Id</TableCell>
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Claim</TableCell>
                        <TableCell>Amount (PGK)</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>View Files</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.policyHolderClaims.map((row: any, idx: number) => (
                        <TableRow key={idx}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.employeeNo}</TableCell>
                            <TableCell>{row.label}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                                <Stack direction={"row"} spacing={1}>
                                    <span>
                                {row.status}
                                        </span>
                                    <Tooltip title={row.reason}>
                                        <InfoOutlined style={row.status === "Rejected" ? {display:"inline"} : {display:"none"}} color="primary"></InfoOutlined>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Button disabled={row.documents ===""}
                                    variant="contained"
                                    onClick={()=>viewFilesAction(row.id, row.documents)}
                                >
                                    <span>View Files</span>
                                </Button></TableCell>
                            <TableCell>
                                <Button
                                    disabled={row.status ==="Approved"}
                                variant="contained"
                                onClick={()=>{store.PolicyHolderClaimsTableStore.setIdToDelete(row.id); store.PolicyHolderClaimsTableStore.setOpenVerificationDialog(true)}}
                                >
                                <span>Delete</span>
                            </Button></TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
            <Dialog open = {store.PolicyHolderClaimsTableStore.openVerificationDialog}  >
                <DialogTitle>Verification</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.PolicyHolderClaimsTableStore.setOpenVerificationDialog(false)}>No</Button>
                    <Button onClick={()=>{deleteClaim({variables:{id:store.PolicyHolderClaimsTableStore.idToDelete}});store.PolicyHolderClaimsTableStore.setOpenVerificationDialog(false)}}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {store.ViewFilesStore.openfileDialog}  >
                <DialogTitle>List of the Files Attached to the Claim</DialogTitle>
                <DialogContent>
                    <ViewFilesComponent/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.ViewFilesStore.setOpenfileDialog(false)}>Close</Button>

                </DialogActions>
            </Dialog>
        </>
    }
    else {
            return <div style={{alignSelf:'center'}}>There is not any Claim meeting filter criteria</div>;
        }

});
