import {observer} from "mobx-react-lite";
import {DownloadCertificate} from "./DownloadCertificate";
import {PolicyHolderClaimsTable} from "./PolicyHolderClaimsTable";
import {AddPolicyHolderClaim} from "./AddPolicyHolderClaim";
import {PolicyholderClaimFilter} from "./PolicyholderClaimFilter";
import {useStore} from "./providers/StoreContext";

export const ProviderPortal =  observer(()=> {
    const store = useStore();
    store.psnaToolBarStore.setShowRegistrationForm(false);
    return (
        <>
            <DownloadCertificate></DownloadCertificate>
            <AddPolicyHolderClaim></AddPolicyHolderClaim>
            <PolicyholderClaimFilter controlType="Provider"></PolicyholderClaimFilter>
            <PolicyHolderClaimsTable></PolicyHolderClaimsTable>
        </>
    );
});